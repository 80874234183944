header {
  border-bottom: 1px solid #e1e1e1;
  background-color: #fff !important;
  left: 0;
  padding: 0 30px;
}

#sec-change-lang button {
  height: 20px;
}

#btn-lang-en {
  border-right: 2px solid #9b9b9b;
  border-radius: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.no-padding {
  padding: 0;
}

.navbar {
  height: 76px;
}

.flex-end {
  justify-content: flex-end;
}

.flex-start {
  justify-content: flex-start;
}

.lang-button {
  padding: 0 10px;
  margin: 0;
}
