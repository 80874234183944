.apple-btn,
.apple-btn:hover {
  display: flex;
  height: 48px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Greytones-Title, #111);
  cursor: pointer;
  border: none;
}
