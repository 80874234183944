.google-btn,
.google-btn:hover {
  display: flex;
  height: 49px;
  padding: 4px;
  gap: 8px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 2px solid var(--Greytones-Text-light, #707070);
  background: var(--Greytones-White, #fff);
  cursor: pointer;
}
