body,
html,
#root {
  font-size: 16px;
}

/* this targets the div rendered by DSThemeProvider */
#root > div {
  height: 100%;
}
