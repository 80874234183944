body,
html,
#root {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  height: 100%;
  background-color: #fff;
  /* https://github.com/material-components/material-components-web/tree/master/packages/mdc-theme#css-custom-properties */
  --mdc-theme-primary: #32c850;
  --mdc-theme-error: #d60a3d;
  --mdc-typography-font-family: LinetoCircular-Book, sans-serif;
  --link-color: #9b9b9b;
  font-family: LinetoCircular-Book, sans-serif;
  --mdc-typography-button-text-transform: none;
  --mdc-typography-button-letter-spacing: 0px;
  --mdc-typography-button-font-family: LinetoCircular-Medium, sans-serif;
  --field-color: #949494;
  --field-color-typing: #707070;
  --grey-color-light: #9b9b9b;
  --disabled-background: #e2dfe2;
  --disabled-text: rgba(15, 12, 15, 0.5);
  font-size: 16px;
  overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
